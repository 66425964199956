
  import { Component } from 'vue-property-decorator'
  import SupervisorInspections from './Inspections.vue'
  import SupervisorLeads from './Leads.vue'
  import SupervisorSalesPurchases from './SalesPurchases.vue'
  import SupervisorStock from './Stock.vue'
  import SupervisorDashboard from './SupervisorDashboard'

@Component({
  components: {
    SupervisorStock,
    SupervisorLeads,
    SupervisorSalesPurchases,
    SupervisorInspections,
  },
})
  export default class SupervisorAll extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        await this.getGeneralResults()
      }
    }

  // Getters

  // Watchers
  }
